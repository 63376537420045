
import { Component, Vue } from 'vue-property-decorator';

import * as StatServices from '@/services/StatServices';

@Component
export default class AverageClicksPerVisitorChart extends Vue {
  loading = false;

  series = [] as any[];
  chartOptions = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    },
    fill: {
      opacity: 1
    },
  }

  async mounted() {
    this.loading = true;
    let data = await StatServices.AverageClicksPerVisitor();

    data = data.filter((e: any) => new Date(e.date) > new Date(2022, 12, 1))

    this.series = [
      {
        name: 'Gemiddelde klik per bezoeker',
        data: data.map((e: any) => e.averageClickPerVisitor)
      },
      {
        name: 'Gemiddeld doorklik per bezoeker',
        data: data.map((e: any) => e.averageClickTroughPerVisitor)
      },
    ]


    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        categories: data.map((e: any) => e.formattedDate)
      }
    }

    this.loading = false;
  }
}
